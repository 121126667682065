import React, { useMemo, useState } from 'react'
import Text from '~src/new/shared/ui/Text/Text'
import cls from './input-field.module.scss'
import classnames from 'classnames'
import { SvgIcon } from '~src/new/shared/ui/SvgIcon/SvgIcon'
import { ReactComponent as EyePassword } from '~shared/assets/img/icons/eye_password.svg'
import { ReactComponent as CalendarIcon } from '~shared/assets/img/icons/calendar-icon.svg'

/**
 * @typedef {Object} InputFieldProps
 * @property {string} [label]
 * @property {React.ReactNode} [before]
 * @property {React.ReactNode} [after]
 * @property {string} [errorLabel]
 */

/**
 *
 * @param {React.InputHTMLAttributes<HTMLInputElement> & InputFieldProps} props
 * @return {JSX.Element}
 */
export const InputField = props => {
  const { label, defaultValue, className, after, before, errorLabel, ...inputProps } = props
  const [inputType, setInputType] = useState(inputProps.type)
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  const isPassword = inputProps.type === 'password'
  const isDate = inputProps.type === 'date'

  const passwordToggleBtn = useMemo(() => {
    if (!isPassword) return null
    return (
      <SvgIcon
        icon={EyePassword}
        name={isPasswordVisible ? 'eye' : 'eye-off'}
        className={classnames(cls.passwordToggleBtn, {
          [cls.passwordToggleBtnVisible]: isPasswordVisible,
        })}
        style={{ position: 'static' }}
        onClick={() => {
          setInputType(isPasswordVisible ? 'password' : 'text')
          setIsPasswordVisible(!isPasswordVisible)
        }}
      />
    )
  }, [inputProps.type, isPasswordVisible])

  const dateBtn = useMemo(() => {
    if (!isDate) return null
    return <SvgIcon icon={CalendarIcon} name={'calendar-icon'} className={cls.calendarIcon} />
  }, [inputProps.type])

  return (
    <div className={cls.container}>
      {label ? (
        <Text variant="TEXT12" className={cls.label}>
          {label}
        </Text>
      ) : null}
      <div
        className={classnames(cls.input, cls.maxWidth, className, {
          [cls.error]: Boolean(errorLabel),
          [cls.disabled]: inputProps.disabled,
          [cls.password]: isPassword,
        })}
      >
        {before}
        <input {...inputProps} type={inputType} defaultValue={defaultValue} />
        {after ?? passwordToggleBtn ?? dateBtn}
      </div>
      {errorLabel ? (
        <Text variant="TEXT12" className={cls.errorLabel}>
          {errorLabel}
        </Text>
      ) : null}
    </div>
  )
}
