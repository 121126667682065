import React, { useCallback, useRef } from 'react'

export const useDebounce = (func, delay) => {
  const timeoutRef = useRef(null)
  const promises = useRef([])

  const debouncedFunction = useCallback(
    (...args) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
        promises.current.forEach(resolve => resolve())
      }
      const promise = new Promise(resolve => {
        promises.current.push(resolve)
      })
      timeoutRef.current = setTimeout(async () => {
        const result = await func(...args)
        promises.current.forEach(resolve => resolve(result))
      }, delay)
      return promise
    },
    [func, delay],
  )

  return debouncedFunction
}
