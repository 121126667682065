import React from 'react'
import classnames from 'classnames'
import Text from '~src/new/shared/ui/Text/Text'
import Icon from '@material-ui/core/Icon/Icon'
import cls from './select-label.module.scss'

/**
 * SelectLabel is a functional component that renders a selectable label with an optional clear button.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.className - The CSS class to apply to the label container.
 * @param {Function} props.onClick - A function to call when the label is clicked.
 * @param {boolean} props.isOpen - A flag indicating whether the associated dropdown is open.
 * @param {string} props.label - The label to display when no value is selected.
 * @param {string} props.id - The ID to assign to the label element.
 * @param {boolean} props.noPadding - A flag indicating whether to remove the default padding from the label.
 * @param {string} props.value - The currently selected value.
 * @param {Function} props.onClear - A function to call when the clear button is clicked.
 */

export const SelectLabel = ({
  className,
  onClick,
  isOpen,
  label,
  id,
  noPadding,
  value,
  onClear,
}) => {
  const showValue = !!value

  return (
    <Text
      variant="BUTTON"
      id={id}
      className={classnames(cls.container, className, {
        [cls.noPadding]: noPadding,
        [cls.showValue]: showValue,
      })}
      onClick={onClick}
    >
      {value || label}
      <Icon
        style={{
          transform: isOpen && !showValue ? 'rotate(180deg)' : 'rotate(0deg)',
        }}
        className={cls.icon}
        onClick={onClear}
      >
        {onClear && showValue ? 'close' : 'keyboard_arrow_down_outlined'}
      </Icon>
    </Text>
  )
}
