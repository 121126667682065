import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { renderContent } from '~src/new/shared/ui/RichText/parserRichText'
import { useApi } from '~shared/api/ApiContext'
import { withRouter } from 'react-router-dom'

// refactored RichTextModule to functional component and use new component

const RichTextElement = props => {
  const {
    className,
    wrapClassName,
    style = {},
    history,
    content,
    withoutHeader,
    removeHeader,
    defaultColor,
    children,
  } = props

  const api = useApi()

  return (
    <div
      className={classNames(className)}
      style={{
        ...style,
        boxSizing: 'border-box',
        width: '100%',
      }}
    >
      {renderContent(content || children, {
        api,
        history,
        wrapClassName,
        withoutHeader,
        removeHeader,
        defaultColor,
      })}
    </div>
  )
}

RichTextElement.propTypes = {
  className: PropTypes.string,
  wrapClassName: PropTypes.string,
  style: PropTypes.object,
  content: PropTypes.string,
  history: PropTypes.object,
  withoutHeader: PropTypes.bool,
  removeHeader: PropTypes.bool,
  defaultColor: PropTypes.string,
}

export const RichText = withRouter(RichTextElement)
