import React from 'react'
import classNames from 'classnames'

import Text from '~src/new/shared/ui/Text/Text'
import cls from './counter.module.scss'

/**
 * Counter component displays a value, target, and label.
 *
 * @param {Object} props - The component props.
 * @param {number} props.value - The value to display.
 * @param {number} props.target - The target value to display.
 * @param {string} props.label - The label to display.
 * @param {string} [props.className] - The class name to add
 * @returns {JSX.Element} The Counter component.
 */
const Counter = ({ value, target, label, className }) => {
  const haveValue = value !== undefined && value !== null
  const haveTarget = target !== undefined && target !== null

  return (
    <div className={classNames(cls.container, className)}>
      <Text>
        {haveValue && <span className={cls.value}>{value}</span>}
        {haveValue && haveTarget && <span className={classNames(cls.target, cls.divider)}>/</span>}
        {haveTarget && <span className={cls.target}>{target}</span>}
      </Text>
      <Text variant="TEXT12" className={cls.label}>
        {label}
      </Text>
    </div>
  )
}

export default Counter
