import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import cls from './arrow-round-button.module.scss'
import Icon from '@material-ui/core/Icon/Icon'
import { SvgIcon } from '~src/new/shared/ui/SvgIcon/SvgIcon'
import { ReactComponent as DoubleArrows } from '~shared/assets/img/icons/arrows/double-arrow.svg'

const SETTINGS_BY_DIRECTION = {
  left: {
    // arrowClass: cls.leftArrow,
    icon: 'keyboard_arrow_left',
    ariaLabel: 'Prev',
  },
  right: {
    // arrowClass: cls.rightArrow,
    icon: 'keyboard_arrow_right',
    ariaLabel: 'Next',
  },
}

const VARIATIONS = {
  BRAND_COLOR: cls.brandColor,
  OUTLINE: cls.outline,
}

const SIZES = {
  SMALL24: cls.small24,
}

export const ArrowRoundButton = props => {
  const {
    className,
    style,
    direction = 'left',
    id,
    disabled,
    variation,
    size = 'NORMAL',
    onClick,
    isDiv = false,
    isDoubleArrow = false,
  } = props

  const Element = isDiv ? 'div' : 'button'

  let icon = (
    <Icon
      style={{
        fontSize: '24px',
      }}
    >
      {SETTINGS_BY_DIRECTION[direction].icon}
    </Icon>
  )

  if (isDoubleArrow) {
    icon = (
      <SvgIcon
        name={'arrow'}
        icon={DoubleArrows}
        style={{ top: 0, ...(direction === 'right' ? { transform: 'rotate(180deg)' } : {}) }}
      />
    )
  }

  return (
    <Element
      id={id}
      disabled={disabled}
      aria-label="Next"
      className={classNames(
        className,
        cls.arrow,
        disabled && cls.disabled,
        VARIATIONS[variation],
        SIZES[size],
        isDoubleArrow && cls.doubleArrow,
      )}
      onClick={onClick}
      style={style}
    >
      {icon}
    </Element>
  )
}

ArrowRoundButton.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  direction: PropTypes.oneOf(['left', 'right']),
  id: PropTypes.string,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  ariaLabel: PropTypes.string,
  onClick: PropTypes.func,
  variation: PropTypes.oneOf(['BRAND_COLOR', 'OUTLINE']),
  size: PropTypes.oneOf(['SMALL24']),
  isDiv: PropTypes.bool,
  isDoubleArrow: PropTypes.bool,
}
