import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import SelectPopup from '~src/new/shared/ui/SelectPopup/SelectPopup'
import { SelectLabel } from '~src/new/shared/ui/SelectLabel/SelectLabel'
import cls from '../table-view.module.scss'

/**
 *
 * @param {TableViewCellSelectType} cell
 * @param {Object} classes
 * @return {JSX.Element}
 */
const CellSelect = ({ cell }) => {
  const { label, value, options, onChange, beforeOptions, valueLabel, showValue } = cell

  const [isOpen, setIsOpen] = useState(false)

  const onClick = e => {
    if (isOpen) return onClose()

    setIsOpen(true)
  }

  const onClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  return (
    <>
      <SelectLabel
        label={label}
        onClick={onClick}
        isOpen={isOpen}
        noPadding
        value={showValue ? valueLabel || value : null}
        onClear={showValue ? () => onChange(null) : null}
      />
      <SelectPopup
        value={value}
        options={options}
        isOpen={isOpen}
        onClose={onClose}
        onChange={onChange}
        beforeOptions={beforeOptions}
        className={cls.selectPopup}
      />
    </>
  )
}

CellSelect.propsTypes = {
  cell: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    options: PropTypes.array,
    onChange: PropTypes.func,
  }),
}

export default CellSelect
