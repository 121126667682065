import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Text from '~src/new/shared/ui/Text/Text'
import Icon from '@material-ui/core/Icon/Icon'
import Tooltip from '~src/new/shared/ui/Tooltip/Tooltip'
import Colors from '~shared/assets/styles/colors'

const styles = ({ spacing: { unit }, breakpoints }) => ({
  icon: {
    fontSize: '16px',
    color: Colors.Aqua,
    marginLeft: '4px',
    marginTop: '-5px',
  },
  text: {
    display: 'flex',
    alignItems: 'center',
  },
})

/**
 *
 * @param {TableViewCellInfoType} cell
 * @param {Object} classes
 * @param {string} className
 * @return {JSX.Element}
 */
const CellInfo = ({ cell, classes, className }) => {
  const { infoText, label } = cell

  return (
    <Tooltip text={infoText}>
      <Text variant="BUTTON" className={classes.text}>
        {label}
        <Icon className={classes.icon}>info</Icon>
      </Text>
    </Tooltip>
  )
}

CellInfo.propsTypes = {
  cell: PropTypes.shape({
    label: PropTypes.string,
    infoText: PropTypes.string,
  }),
}

export default withStyles(styles)(CellInfo)
