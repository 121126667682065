import React from 'react'
import * as PropTypes from 'prop-types'

import cls from './spec-item.module.scss'
import Text from '~src/new/shared/ui/Text/Text'
import { CopyButton } from '~src/new/features/CopyButton/CopyButton'
import classNames from 'classnames'

/**
 * A component that represents a specification item.
 * It displays the name and value of the item, with an optional copy button.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.name - The name of the specification item.
 * @param {string} props.value - The value of the specification item.
 * @param {boolean} [props.withCopy] - Whether to show a copy button for the value.
 * @param {string} [props.copyLabel] - The label for the copy button.
 * @param {Object} [props.style] - Additional inline styles to be applied to the SpecItem component.
 * @param {string} [props.className] - Additional CSS class names to be applied to the SpecItem component.
 * @returns {JSX.Element|null} The rendered SpecItem component.
 */
export const SpecItem = props => {
  const { name, value, withCopy, copyLabel, style, className } = props

  const isText = typeof value === 'string'

  if (!name || !value) return null

  return (
    <div className={classNames(cls.specItem, className)} style={style}>
      <Text variant="BUTTON" className={cls.specTitle}>
        {name}
      </Text>
      <div className={cls.content}>
        {isText ? (
          <Text className={cls.specValue} variant="BIG_TEXT">
            {value}
          </Text>
        ) : (
          value
        )}
        {!!withCopy && (
          <CopyButton className={cls.copy} value={value} label={copyLabel || 'скопировать'} />
        )}
      </div>
    </div>
  )
}

SpecItem.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  withCopy: PropTypes.bool,
  copyLabel: PropTypes.string,
}
