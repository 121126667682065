import React from 'react'
import cls from './textarea.module.scss'
import classNames from 'classnames'

/**
 * @typedef {Object} TextAreaProps
 * @property {boolean} [isAutoHeight]
 */

const autoHeight = element => {
  element.style.height = 'auto'
  element.style.height = `${element.scrollHeight + 2}px`
}

/**
 * @param {React.TextareaHTMLAttributes<HTMLTextAreaElement> & TextAreaProps} props
 * @return {JSX.Element}
 * @constructor
 */
export const TextArea = props => {
  const { value, onChange, placeholder, isAutoHeight, className, rows, onInput, ...params } = props

  return (
    <textarea
      {...params}
      ref={ref => {
        if (!ref) return
        if (isAutoHeight) autoHeight(ref)
        if (params.ref) {
          if (typeof params.ref === 'function') params.ref(ref)
          else params.ref.current = ref
        }
      }}
      rows={rows ?? 1}
      className={classNames(className, cls.textArea)}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      onInput={isAutoHeight ? e => autoHeight(e.target) || onInput(e) : onInput}
    />
  )
}
