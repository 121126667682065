import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import cls from './select-popup.module.scss'
import Text from '~src/new/shared/ui/Text/Text'
import { SvgIcon } from '~src/new/shared/ui/SvgIcon/SvgIcon'

/**
 * SelectPopup is a functional component that renders a popup with selectable options.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} [props.className] - The CSS class to apply to the popup container.
 * @param {string} [props.classNameOption] - The CSS class to apply to the popup container.
 * @param {string} [props.idContainer] - The ID of the container element.
 * @param {boolean} props.isOpen - A flag indicating whether the popup is open.
 * @param {Function} props.onClose - A function to call when the popup is closed.
 * @param {Function} props.onChange - A function to call when an option is selected.
 * @param {Array} props.options - An array of options to display in the popup. Each option is an object with `label` and `value` properties.
 * @param {string} props.value - The currently selected option value.
 * @param {React.CSSProperties} [props.style] - The inline style for the popup.
 * @param {JSX.Element} [props.beforeOptions] - The content to render before the options.
 */
const SelectPopup = props => {
  const {
    className,
    classNameOption,
    idContainer,
    isOpen,
    onClose,
    onChange,
    options,
    value,
    beforeOptions,
    style,
  } = props

  useEffect(() => {
    if (!isOpen) return
    const onClick = e => {
      if (!e.target.closest(`.${cls.container}`)) {
        onClose()
      }
    }
    document.addEventListener('click', onClick)
    return () => document.removeEventListener('click', onClick)
  }, [isOpen, onClose])

  const optionsList = useMemo(() => {
    if (!onChange) console.error('onChange is not defined in SelectPopup')
    return options.map(option => {
      const isActive = option.value === value
      return (
        <div
          id={idContainer && `${idContainer}_${option.value}`}
          key={option.value}
          className={classNames(
            cls.item,
            classNameOption,
            isActive && cls.activeItem,
            option.disabled && cls.disabledItem,
          )}
          onClick={() =>
            (onClose && onClose()) ||
            (onChange && !option.disabled && onChange(isActive ? null : option.value))
          }
        >
          <Text variant="MAIN">{option.label}</Text>
          {option.icon ? <SvgIcon icon={option.icon} name="point type" /> : null}
        </div>
      )
    })
  }, [options, onChange, onClose])

  return (
    <div className={classNames(cls.container, className, isOpen && cls.opened)} style={style}>
      {beforeOptions}
      {optionsList}
    </div>
  )
}

SelectPopup.propTypes = {
  className: PropTypes.string,
  classNameOption: PropTypes.string,
  idContainer: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  beforeOptions: PropTypes.node,
}

export default SelectPopup
