import React from 'react'
import classNames from 'classnames'
import { typePointToIconFillSvg, typePointToIconTransparentSvg } from '~src/new/entities/Points'
import Text from '~src/new/shared/ui/Text/Text'
import { SvgIcon } from '~src/new/shared/ui/SvgIcon/SvgIcon'
import cls from './points-counter.module.scss'

const renderSvgIcon = (id, source, size, isCrowns) => {
  return (
    <SvgIcon
      icon={source[id] ?? source.default}
      name={'point type'}
      style={{ width: size, height: size, top: isCrowns ? '-4px' : '3px', left: '3px' }}
    />
  )
}

const PointCounter = ({ value, target, label, className, id, isCrowns, size, isMobile }) => {
  const haveValue = value !== undefined && value !== null
  const haveTarget = target !== undefined && target !== null

  return (
    <div className={className || cls.container}>
      <Text>
        {haveValue && (
          <>
            <span className={cls.value}>{value}</span>
            {renderSvgIcon(id, typePointToIconFillSvg, !isMobile ? size : 16, isCrowns)}
          </>
        )}
        {haveValue && haveTarget && <span className={classNames(cls.target, cls.divider)}>/</span>}
        {haveTarget && (
          <>
            <span className={cls.target}>{target}</span>
            {renderSvgIcon(id, typePointToIconTransparentSvg, !isMobile ? size : 12, isCrowns)}
          </>
        )}
      </Text>
      <Text variant="TEXT12" className={cls.label}>
        {label}
      </Text>
    </div>
  )
}

export default PointCounter
