import React, { useMemo } from 'react'
import * as PropTypes from 'prop-types'
import classNames from 'classnames'
import cls from './link.module.scss'
import { Link as RouterLink } from 'react-router-dom'
import Text from '~src/new/shared/ui/Text/Text'

export const Link = props => {
  const {
    children,
    variation = 'BRAND',
    asTextVariant,
    className,
    container = RouterLink,
    style,
    ...extra
  } = props

  const Container = useMemo(() => container, [container])

  const child = useMemo(() => {
    if (asTextVariant) {
      return (
        <Text
          style={{
            display: 'inline',
          }}
          variant={asTextVariant}
        >
          {children}
        </Text>
      )
    }
    return children
  }, [asTextVariant, children])

  return (
    <Container className={classNames(cls.link, cls[variation], className)} style={style} {...extra}>
      {child}
    </Container>
  )
}

Link.defaultProps = {
  variation: 'BRAND',
}

Link.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  variation: PropTypes.oneOf(['BRAND']),
  asTextVariant: Text.propTypes.variant,
  container: PropTypes.elementType,
  id: PropTypes.string,
  onClick: PropTypes.func,

  to: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
}
