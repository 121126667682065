import React from 'react'
import classNames from 'classnames'
import cls from './grid.module.scss'

const SIZES = ['xs', 'sm', 'md', 'lg', 'xl']

/**
 * Grid component that can act as a container or an item.
 *
 * @param {GridProps} props - The properties passed to the component.
 * @returns {JSX.Element} The rendered Grid component.
 */
export const Grid = props => {
  const {
    alignContent,
    alignItems,
    container,
    item,
    direction,
    spacing,
    justify,
    wrap,
    zeroMinWidth,
    xs,
    sm,
    md,
    lg,
    xl,
    className,
    children,
    page,
    flex,
    ...other
  } = props

  if (page) return <div className={classNames(cls.page, className)}>{children}</div>

  const breakpointClasses = SIZES.reduce((acc, breakpoint) => {
    const propValue = props[breakpoint]

    if (propValue != null) {
      let classKey = ''

      if (propValue === true) {
        classKey = `grid-${breakpoint}-true`
      } else if (propValue === 'auto') {
        classKey = `grid-${breakpoint}-auto`
      } else {
        classKey = `grid-${breakpoint}-${propValue}`
      }

      acc.push(cls[classKey])
    }

    return acc
  }, [])

  const optionClasses = {
    [cls.container]: container,
    [cls.item]: item || !container,
    [cls.flex]: !container && (flex || direction || wrap || alignItems || alignContent || justify),
    [cls[`direction-${direction}`]]: direction,
    [cls[`wrap-${wrap}`]]: wrap,
    [cls[`align-items-${alignItems}`]]: alignItems,
    [cls[`align-content-${alignContent}`]]: alignContent,
    [cls[`justify-${justify}`]]: justify,
    [cls.zeroMinWidth]: zeroMinWidth,
    [cls[`spacing-${spacing}`]]: spacing != null && container,
  }

  const classes = [className, ...breakpointClasses, optionClasses].filter(Boolean)

  return (
    <div className={classNames(classes)} {...other}>
      {children}
    </div>
  )

  // const { children, className, page, container, item, col, noGutter } = props
  // if (page)
  //   return (
  //     <div className={classNames(cls.page, className, container && cls.gridContainer)}>
  //       {children}
  //     </div>
  //   )
  // const classNameSettings = {
  //   [cls.gridContainer]: container,
  //   [cls.gridItem]: item || !container,
  //   [cls[`col-${col}`]]: col,
  //   [cls.gutter]: !noGutter,
  // }
  //
  // SIZES.forEach(size => {
  //   if (props[size]) {
  //     classNameSettings[cls[`col-${size}-${props[size]}`]] = true
  //   }
  // })
  //
  // return <div className={classNames(className, classNameSettings)}>{children}</div>
}
