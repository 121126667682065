import React from 'react'
import * as PropTypes from 'prop-types'
import classNames from 'classnames'

import Text from '~src/new/shared/ui/Text/Text'
import cls from '../modal.module.scss'

export const ModalTitle = props => {
  const { children, className, style, ...extra } = props

  return (
    <Text className={classNames(cls.title, className)} {...extra}>
      {children}
    </Text>
  )
}

ModalTitle.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
}
