import React, { useMemo } from 'react'
import * as PropTypes from 'prop-types'
import classNames from 'classnames'

import cls from './radio-select.module.scss'
import { SvgIcon } from '~src/new/shared/ui/SvgIcon/SvgIcon'

export const RadioSelect = ({ options, value, onChange }) => {
  const parseOption = useMemo(() => {
    return options.map(option => {
      if (typeof option === 'string') return { label: option, value: option }
      return {
        label: option.label,
        value: option.value,
        icon: option.icon,
      }
    })
  }, options)

  const checkedOption = useMemo(() => {
    return parseOption.find(option => option.value === value)
  }, [parseOption, value])

  return (
    <div className={cls.options}>
      {parseOption.map(option => (
        <label
          className={classNames(cls.optionRadio, {
            [cls.checked]: option === checkedOption,
          })}
          key={option.value}
        >
          <input
            type="radio"
            value={option.value}
            checked={option === checkedOption}
            onChange={() => onChange(option.value)}
            className={cls.input}
          />
          <label className={cls.fakeRadio} />
          {option.label}
          {!!option.icon && <SvgIcon name={option.label} icon={option.icon} />}
        </label>
      ))}
    </div>
  )
}

RadioSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}
