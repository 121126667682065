import React, { useState } from 'react'
import { SwiperCore } from '../SwiperCore/SwiperCore'
import cls from './badge-slider.module.scss'
import classNames from 'classnames'
import Text from '../Text/Text'
import { Button } from '../Button/Button'

const ELEMENTS_TO_SHOW = 3

/**
 * @typedef {string|number} Badge
 */

/**
 * Renders a badge slider component.
 *
 * @param {Object} props - The component props.
 * @param {Badge[]} props.badges - The array of badges to display.
 * @param {Badge} props.activeBadge - The index of the active badge.
 * @param {(badge: Badge)=>void} props.setActiveBadge - The function to set the active badge.
 * @param {string} [props.className] - The additional CSS class name for the component.
 * @param {React.CSSProperties} [props.style] - The inline style for the selected option.

 * @returns {JSX.Element} The rendered BadgeSlider component.
 */
export const BadgeSlider = ({ badges, activeBadge, setActiveBadge, className, style }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [maxIndex, setMaxIndex] = useState(0)

  const badgesHtml = badges.map((badge, index) => (
    <div
      key={index}
      className={classNames(cls.badge, activeBadge === badge ? cls.activeBadge : null)}
      onClick={() => setActiveBadge(badge)}
    >
      <Text variant="BUTTON"> {badge}</Text>
    </div>
  ))

  const changeActiveSlide = direction => {
    const nextIndex = activeIndex + direction
    if (nextIndex < 0 || nextIndex > maxIndex) return
    setActiveIndex(nextIndex)
  }

  const canPrev = activeIndex > 0
  const canNext = activeIndex < maxIndex

  return (
    <div className={classNames(cls.container, className)} style={style}>
      <Button
        isArrow
        className={classNames(cls.arrow, cls.prev, {
          [cls.show]: canPrev,
        })}
        onClick={() => changeActiveSlide(-1)}
      />

      <SwiperCore
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        setMaxIndex={setMaxIndex}
        elements={badgesHtml}
        classNameElements={cls.swiper}
        elementsToShow={ELEMENTS_TO_SHOW}
      />

      <Button
        isArrow
        className={classNames(cls.arrow, {
          [cls.show]: canNext,
        })}
        onClick={() => changeActiveSlide(1)}
      />
    </div>
  )
}
