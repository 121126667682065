import React, { useMemo } from 'react'
import * as PropTypes from 'prop-types'
import classNames from 'classnames'
import cls from './text.module.scss'
import { useWidth } from '~src/new/app/providers/WidthProvider'

/** @typedef {'TITLE_PAGE' | 'HEADER' | 'SUBHEADER' | 'BUTTON' | 'CAMBAY_26' | 'CAMBAY_32' | 'BIG_EMPTY_LABEL' | 'TEXT12' | 'TEXT14' | 'TEXT16' | 'MAIN' | 'BIG_TEXT' | 'LINK'} VariationEnum */

/**
 * A component that renders text with different variants and responsive behavior.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {any} props.children - The content to be rendered inside the Text component.
 * @param {string} [props.className] - Additional CSS class names to be applied to the Text component.
 * @param {React.CSSProperties} [props.style] - Additional inline styles to be applied to the Text component.
 * @param {VariationEnum} [props.variant] - The variant of the text. Can be one of: 'HEADER', 'SUBHEADER', 'BUTTON', 'TEXT12', 'TEXT14', 'MAIN', 'BIG_TEXT'.
 * @param {VariationEnum} [props.mobile] - The variant of the text to be used on mobile devices. Can be one of: 'HEADER', 'SUBHEADER', 'BUTTON', 'TEXT12', 'TEXT14', 'MAIN', 'BIG_TEXT'.
 * @returns {JSX.Element} The rendered Text component.
 */
const Text = props => {
  const { children, className, style, variant, mobile, ...extra } = props

  const { isMobile } = useWidth()

  const responsiveVariant = useMemo(() => (isMobile ? mobile : variant), [
    isMobile,
    mobile,
    variant,
  ])

  if (!children) return null

  return (
    <p
      className={classNames(cls.base, cls[responsiveVariant || variant], className)}
      style={style}
      {...extra}
    >
      {children}
    </p>
  )
}

Text.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  variant: PropTypes.oneOf([
    'TITLE_PAGE',
    'HEADER',
    'SUBHEADER',
    'BUTTON',
    'CAMBAY_26',
    'CAMBAY_32',
    'BIG_EMPTY_LABEL',
    'TEXT12',
    'TEXT14',
    'TEXT16',
    'MAIN',
    'BIG_TEXT',
    'LINK',
  ]),

  mobile: PropTypes.oneOf([
    'TITLE_PAGE',
    'HEADER',
    'SUBHEADER',
    'BUTTON',
    'CAMBAY_26',
    'CAMBAY_32',
    'BIG_EMPTY_LABEL',
    'TEXT12',
    'TEXT14',
    'TEXT16',
    'MAIN',
    'BIG_TEXT',
    'LINK',
  ]),
}
export default Text
export { Text }
