import React, { useState, useEffect, useMemo } from 'react'
import { useSpring } from '@react-spring/web'
import { useSwipeTracker } from '~src/new/shared/lib/useSwipeTracker'

export const useModalAnimation = (isOpen, onClose, isDisabled) => {
  const [isRenderChildren, setIsRenderChildren] = useState(isOpen)

  // Анимация с помощью useSpring
  const [style, api] = useSpring(() => ({
    from: { y: '100%' }, // Начальная позиция (вне экрана)
    to: { y: '100%' }, // Конечная позиция по умолчанию
    config: { tension: 250, friction: 25 },
  }))

  useEffect(() => {
    if (isDisabled) return
    if (isOpen) {
      setIsRenderChildren(true)
      api.start({ y: '0%' }) // Поп-ап выезжает и занимает 97% экрана
    } else {
      api.start({ y: '100%' }) // Поп-ап уезжает вниз
    }
  }, [isOpen, api, isDisabled])

  const {
    swipeDirection,
    distance,
    isSwiping,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
  } = useSwipeTracker({ onSwipeEnd })

  function onSwipeEnd(direction, distance) {
    if (direction !== 'down') return

    const height = window.innerHeight
    if (distance > height / 2) {
      api.start({
        y: '100%',
        onRest: () => {
          setIsRenderChildren(false)
          onClose()
        },
      })
    } else {
      api.start({ y: '0%' })
    }
  }

  useEffect(() => {
    if (isDisabled) return
    if (!isSwiping || swipeDirection !== 'down') return
    const height = window.innerHeight
    const yPercentage = (distance.y / height) * 100
    api.start({ y: `${yPercentage}%` })
  }, [isSwiping, distance.y, onClose, isDisabled, api, swipeDirection])

  const handleSwipe = useMemo(() => {
    return {
      onTouchStart: handleTouchStart,
      onTouchMove: handleTouchMove,
      onTouchEnd: handleTouchEnd,
    }
  }, [handleTouchStart, handleTouchMove, handleTouchEnd])

  return { style, isRenderChildren, handleSwipe }
}
