import React, { useEffect, useState } from 'react'
import Text from '~src/new/shared/ui/Text/Text'
import cls from './checkbox-field.module.scss'
import { Checkbox } from './Checkbox'
import { useWidth } from '~src/new/app/providers/WidthProvider'
import { Switch } from '~src/new/shared/ui/Switch/Switch'

/**
 *
 * @param {React.InputHTMLAttributes<HTMLInputElement> & {description:string}} props
 * @return {JSX.Element}
 */
export const CheckboxField = props => {
  const { checked, onChange, label, description } = props

  const [checkedState, setCheckedState] = useState(checked)

  const { isMobile } = useWidth()

  useEffect(() => {
    setCheckedState(checked)
  }, [checked])

  const handleChange = e => {
    setCheckedState(e.target.checked)
    onChange &&
      onChange({
        target: {
          value: e.target.checked,
        },
      })
  }

  const inputProps = {
    checked: checkedState,
    onChange: handleChange,
    className: cls.input,
  }

  const checkboxElement = isMobile ? <Switch {...inputProps} /> : <Checkbox {...inputProps} />

  return (
    <label className={cls.container}>
      {checkboxElement}
      <div className={cls.text}>
        <Text variant="BUTTON" className={cls.label}>
          {label}
        </Text>
        <Text variant="TEXT12" className={cls.description}>
          {description}
        </Text>
      </div>
    </label>
  )
}
