import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import * as PropTypes from 'prop-types'

import cls from './animation-mount.module.scss'

export const AnimationMount = ({ children }) => {
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setTimeout(() => setMounted(true))
  }, [])

  // return children

  return <div className={classNames(cls.animation, mounted && cls.visible)}>{children}</div>
}

AnimationMount.propTypes = {
  children: PropTypes.node.isRequired,
}
