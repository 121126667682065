import React from 'react'
import * as PropTypes from 'prop-types'
import classNames from 'classnames'
import { ReactComponent as CrossIcon } from '~shared/assets/img/icons/cross-icon.svg'
import cls from './pin-filter.module.scss'
import Text from '~src/new/shared/ui/Text/Text'

export const PinFilter = props => {
  const { label, onClick } = props

  return (
    <div className={classNames(cls.pin)}>
      <Text variant="TEXT14" className={cls.label}>
        {label}
      </Text>
      <CrossIcon onClick={onClick} />
    </div>
  )
}

PinFilter.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}
