async function getImage(imagePath) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.crossOrigin = 'Anonymous'
    img.src = imagePath
    img.onload = () => resolve(img)
    img.onerror = reject
  })
}

function getCanvasByImg(img) {
  const canvas = document.createElement('canvas')
  const c = canvas.getContext('2d')
  c.width = canvas.width = img.width
  c.height = canvas.height = img.height
  c.clearRect(0, 0, c.width, c.height)
  c.drawImage(img, 0, 0, img.width, img.height)
  return c // returns the context
}

function rgbToHex(r, g, b) {
  if (r > 255 || g > 255 || b > 255) throw 'Invalid color component'
  return ((r << 16) | (g << 8) | b).toString(16)
}

function pad(hex) {
  return ('000000' + hex).slice(-6)
}

function getColors(c) {
  let col
  const colors = {}
  let pixels, r, g, b, a
  r = g = b = a = 0
  pixels = c.getImageData(0, 0, c.width, c.height)
  for (let i = 0, data = pixels.data; i < data.length; i += 4) {
    r = data[i]
    g = data[i + 1]
    b = data[i + 2]
    a = data[i + 3] // alpha
    // skip pixels >50% transparent
    if (a < 255 / 2) continue
    col = pad(rgbToHex(r, g, b))
    if (!colors[col]) colors[col] = 0
    colors[col]++
  }
  return colors
}

export const getImageColor = async imagePath => {
  const img = await getImage(imagePath)

  const canvas = getCanvasByImg(img, { width: img.width, height: img.height })
  const colors = getColors(canvas)
  const sortColors = Object.keys(colors).sort((a, b) => colors[b] - colors[a])

  let mainColor = sortColors[0]
  if (sortColors[0] === 'ffffff' || sortColors[0] === '000000') {
    mainColor = sortColors[1]
  }

  return mainColor
}

export function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}

export function getFontColor(bgColor) {
  const rgb = hexToRgb(bgColor)
  return rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114 > 186 ? '#000' : '#fff'
}
