import React, { useMemo } from 'react'
import classNames from 'classnames'
import cls from './swiper-pagination-dots.module.scss'

/**
 *
 * @param {string} className
 * @param {object} style
 * @param {number} count
 * @param {number} activeIndex
 * @return {JSX.Element}
 * @constructor
 */
export const SwiperPaginationDots = ({ className, style, count = 0, activeIndex }) => {
  const items = useMemo(() => Array(count).fill(1), [count])

  return (
    <div className={classNames(cls.paginationContainer, className)} style={style}>
      {items.length > 0 &&
        items.map((item, index) => (
          <div
            className={classNames(cls.pagination, {
              active: index === activeIndex,
            })}
          ></div>
        ))}
    </div>
  )
}
