import { useCallback, useEffect, useState, useLayoutEffect } from 'react'

const useBrowserLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : () => {}

export const getViewportSize = () => {
  if (window.visualViewport) {
    // visualViewport is a new prop intended for this exact behavior, prefer it
    // over all else when available
    // https://developer.mozilla.org/en-US/docs/Web/API/Visual_Viewport_API
    return [window.visualViewport.width, window.visualViewport.height]
  }

  return [
    window.innerWidth,
    // window.innerHeight gets updated when a user opens the soft keyboard, so
    // it should be preferred over documentElement.clientHeight
    // Want more? https://blog.opendigerati.com/the-eccentric-ways-of-ios-safari-with-the-keyboard-b5aa3f34228d
    window.innerHeight,
  ]
}

/**
 * Returns the viewport size. This can also be used as a dependency in a
 * useEffect to trigger an update when the browser resizes.
 * @returns {[number, number]} The viewport size as [width, height]
 */
export const useViewportSize = () => {
  const [viewportSize, setViewportSize] = useState()
  const updateViewportSize = useCallback(() => {
    const viewportSize = getViewportSize()

    setViewportSize(oldViewportSize => {
      if (
        oldViewportSize &&
        oldViewportSize[0] === viewportSize[0] &&
        oldViewportSize[1] === viewportSize[1]
      ) {
        // Maintain old instance to prevent unnecessary updates
        return oldViewportSize
      }

      return viewportSize
    })
  }, [])
  useBrowserLayoutEffect(updateViewportSize, [updateViewportSize])

  useEffect(() => {
    const effectTwice = () => {
      updateViewportSize()
      // Closing the OSK in iOS does not immediately update the visual viewport
      // size :<
      setTimeout(updateViewportSize, 300)
    }

    window.addEventListener('resize', effectTwice)
    // From the top of my head this used to be required for older browsers since
    // this didn't trigger a resize event. Keeping it in to be safe.
    window.addEventListener('orientationchange', effectTwice)
    // This is needed on iOS to resize the viewport when the Virtual/OnScreen
    // Keyboard opens. This does not trigger any other event, or the standard
    // resize event.
    window.visualViewport?.addEventListener('resize', effectTwice)

    return () => {
      window.removeEventListener('resize', effectTwice)
      window.removeEventListener('orientationchange', effectTwice)
      window.visualViewport?.removeEventListener('resize', effectTwice)
    }
  }, [updateViewportSize])

  return viewportSize || []
}
