import { useEffect, useState } from 'react'
import sizeImagePath from '~shared/utils/sizeImagePath'

const getLoadingStyle = ({ path, withPreview, loadingStyle }) => {
  if (withPreview) {
    return {
      backgroundImage: `url(${sizeImagePath(path, 'xxs')})`,
      filter: 'blur(3px)',
    }
  }
  return loadingStyle
}

export const useFallbackBackground = ({ path, fallbackStyle, loadingStyle, size, withPreview }) => {
  const [style, setStyle] = useState(
    path ? getLoadingStyle({ path, withPreview, loadingStyle }) : fallbackStyle,
  )
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    if (!path) return setStyle(fallbackStyle)
    let isCancelled = false
    setStyle(getLoadingStyle({ path, withPreview, loadingStyle }))
    const image = new Image()
    const resizePath = sizeImagePath(path, size)
    image.src = resizePath
    image.onload = () => {
      if (isCancelled) return
      setStyle({ backgroundImage: `url(${resizePath})` })
      setIsLoaded(true)
    }
    image.onerror = () => {
      if (isCancelled) return
      setStyle(fallbackStyle)
      setIsLoaded(true)
    }
    return () => {
      isCancelled = true
    }
  }, [path])

  return { style, isLoaded }
}
