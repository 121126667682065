import React, { useState } from 'react'
import classNames from 'classnames'
import * as PropTypes from 'prop-types'
import cls from './select.module.scss'
import SelectPopup from '~src/new/shared/ui/SelectPopup/SelectPopup'
import { SelectLabel } from '~src/new/shared/ui/SelectLabel/SelectLabel'

/**
 * @typedef {{label:string|any, value:any}} option
 */

/**
 * @typedef {Object} SelectProps
 * @property {option[]} options - The options for the select component.
 * @property {option} selected - The currently selected option.
 * @property {Function} onChange - The callback function to handle option change.
 * @property {string} [className] - The additional CSS class name for the component.
 * @property {string} [classNameSelect] - The additional CSS class name for the component.
 * @property {string} [classNameOptions] - The additional CSS class name for the component.
 * @property {React.CSSProperties} [selectedStyle] - The inline style for the selected option.
 * @property {string} [idContainer] - The ID for the container element.
 * @property {number|string} [minWidth] - The minimum width of the select component.
 * @property {React.ReactNode} [beforeOptions] - The content to render before the options.
 */

/**
 * Renders a colored select component.
 *
 * @param {SelectProps} props - The component props.
 *
 * @returns {JSX.Element} The rendered colored select component.
 */
export const Select = ({
  className,
  classNameSelect,
  classNameOptions,
  options,
  selected,
  onChange,
  selectedStyle,
  idContainer,
  beforeOptions,
  minWidth,
}) => {
  const [open, setOpen] = useState(false)

  const changeOpen = () => {
    setOpen(!open)
  }

  const selectedLabel = selected?.label ?? options.find(({ value }) => value === selected)?.label

  return (
    <div className={classNames(cls.container, className)} style={selectedStyle} id={idContainer}>
      <SelectLabel
        className={classNames(cls.select, classNameSelect)}
        id={idContainer && `${idContainer}_select`}
        onClick={changeOpen}
        label={selectedLabel}
        isOpen={open}
      />
      <SelectPopup
        isOpen={open}
        onClose={changeOpen}
        options={options}
        value={selected?.value ?? selected}
        onChange={onChange}
        className={classNameOptions}
        beforeOptions={beforeOptions}
        style={{ minWidth }}
      />
    </div>
  )
}

Select.propTypes = {
  options: PropTypes.array.isRequired,
  selected: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  selectedStyle: PropTypes.object,
  idContainer: PropTypes.string,
  classNameSelect: PropTypes.string,
  classNameOptions: PropTypes.string,
  beforeOptions: PropTypes.node,
  minWidth: PropTypes.number,
}
