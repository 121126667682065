import React from 'react'
import cls from './box.module.scss'
import classNames from 'classnames'
import Text from '~src/new/shared/ui/Text/Text'

/**
 *
 * @param props
 * @param {string} props.className
 * @param {string} props.title
 * @param {JSX.Element} props.actions
 * @param {React.CSSProperties} props.style
 * @param {JSX.Element} props.children
 * @param {boolean} props.isForm
 *
 * @returns {JSX.Element}
 */
export const Box = props => {
  const { children, className, title, actions, style, isForm, ...rest } = props

  const ContentContainer = isForm ? 'form' : 'div'

  return (
    <div className={classNames(className, cls.box)} style={style} {...rest}>
      {title && (
        <Text variant="CAMBAY_26" className={cls.title}>
          {title}
        </Text>
      )}
      <ContentContainer
        className={classNames(cls.content, {
          [cls.withTitle]: !!title,
        })}
      >
        {children}
      </ContentContainer>
      {actions && <div className={cls.actions}>{actions}</div>}
    </div>
  )
}
