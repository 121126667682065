import React from 'react'
import cls from './badge.module.scss'
import classNames from 'classnames'

/**
 * Renders a badge  component.
 *
 * @param {Object} props - The component props.
 * @param {any} props.children - The content to display.
 * @param {string} [props.color] - The color of the badge.
 * @param {string} [props.className] - Additional CSS class names to be
 * @param {React.CSSProperties} [props.style] - Additional inline styles to be applied to the Badge component.
 * @returns {JSX.Element | null} The rendered Badge component.
 */
export const Badge = ({ children, color, className, style }) => {
  if (children === null || children === undefined) return null
  return (
    <div
      className={classNames(cls.badge, className, {
        [cls.withBackground]: !!color,
      })}
      style={{
        ...(style || {}),
        background: color,
      }}
    >
      {children}
    </div>
  )
}
