import React from 'react'
import PropTypes from 'prop-types'

/**
 * Renders an SVG icon component.
 *
 * @param {Object} props - The component props.
 * @param {string|JSX.ElementType} props.icon - The icon source or component.
 * @param {string} [props.name] - The name of the icon.
 * @param {string} [props.className] - The CSS class name for the icon.
 * @param {string} [props.fill] - The fill color for the icon.
 * @param {React.CSSProperties} [props.style] - The inline styles for the icon.
 */
export const SvgIcon = ({ name, className, fill, icon, style, ...props }) => {
  if (!icon) return null

  if (typeof icon === 'string')
    return <img src={icon} alt={`${name} icon`} className={className} style={{ fill }} {...props} />
  else if (typeof icon === 'object') {
    const IconComponent = icon
    return (
      <IconComponent
        className={className}
        style={{
          fill,
          position: 'relative',
          top: '1px',
          cursor: props?.onClick ? 'pointer' : undefined,
          ...style,
        }}
        {...props}
      />
    )
  }
}

SvgIcon.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  fill: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
}
