import React, { useCallback, useState } from 'react'

const DEFAULT_DISTANCE = { x: 0, y: 0, all: 0 }

/**
 * A swipe direction.
 * @typedef {'up'|'down'|'left'|'right'} SwipeDirection
 */

/**
 * A hook to track swipe parameters.
 * @typedef {Object} SwipeTrackerParams
 * @property {(direction: SwipeDirection, distance: number)=>void} [onSwipeEnd] - The swipe end handler.
 * @property {number} [safeDistance] - The safe distance.
 * @property {boolean} [withAxisFixation] - The axis fixation flag.
 * @property {boolean} [ignoreScroll] - The ignore scroll flag.
 */

/**
 * A hook to track swipe return.
 * @typedef {Object} SwipeTrackerReturn
 * @property {SwipeDirection} swipeDirection - The swipe direction.
 * @property {{ x:number, y:number, all:number }} distance - The swipe distance.
 * @property {boolean} isSwiping - The swipe state.
 * @property {function} handleTouchStart - The touch start handler.
 * @property {function} handleTouchMove - The touch move handler.
 * @property {function} handleTouchEnd - The touch end handler.
 */

function hasScrollableParent(event, direction) {
  let el = event.target

  while (el && el !== event.currentTarget) {
    if (isScrollable(el, direction)) {
      return true
    }
    el = el.parentElement
  }

  return false
}

function isScrollable(el, direction) {
  const style = window.getComputedStyle(el)
  const overflowY = style.overflowY
  const isScrollableY = overflowY === 'auto' || overflowY === 'scroll'
  const canScrollY = el.scrollHeight > el.clientHeight
  const isScrollableX = style.overflowX === 'auto' || style.overflowX === 'scroll'
  const canScrollX = el.scrollWidth > el.clientWidth

  if (direction === 'left' || direction === 'right') {
    const isFullScrolledX =
      direction === 'right'
        ? el.scrollLeft === 0
        : el.scrollLeft === el.scrollWidth - el.clientWidth

    return isScrollableX && canScrollX && !isFullScrolledX
  }

  const isFullScrolledY =
    direction === 'down' ? el.scrollTop === 0 : el.scrollTop === el.scrollHeight - el.clientHeight

  return isScrollableY && canScrollY && !isFullScrolledY
}

const getSwipeDirection = ({ dx, dy, direction, withAxisFixation }) => {
  if (withAxisFixation && direction) {
    if (direction === 'left' || direction === 'right') {
      return dx > 0 ? 'right' : 'left'
    } else {
      return dy > 0 ? 'down' : 'up'
    }
  }
  if (Math.abs(dx) > Math.abs(dy)) {
    return dx > 0 ? 'right' : 'left'
  } else {
    return dy > 0 ? 'down' : 'up'
  }
}

const getSwipeDistance = (dx, dy, startX, startY) => {
  const x = dx - startX
  const y = dy - startY
  return {
    x,
    y,
    all: Math.sqrt(x ** 2 + y ** 2),
  }
}

/**
 * A hook to track swipe gestures.
 * @param {SwipeTrackerParams} [params] - The swipe tracker parameters
 * @return {SwipeTrackerReturn} The swipe tracker return.
 */
export const useSwipeTracker = ({
  onSwipeEnd,
  safeDistance = 2,
  withAxisFixation = true,
  ignoreScroll = false,
} = {}) => {
  const [swipeDirection, setSwipeDirection] = useState(null)
  const [startX, setStartX] = useState(null)
  const [startY, setStartY] = useState(null)
  const [distance, setDistance] = useState(DEFAULT_DISTANCE)
  const [isSwiping, setIsSwiping] = useState(false)

  const resetState = useCallback(() => {
    setDistance(DEFAULT_DISTANCE)
    setStartX(null)
    setStartY(null)
    setIsSwiping(false)
    setSwipeDirection(null)
  }, [setDistance, setStartX, setStartY, setIsSwiping])

  const handleTouchStart = useCallback(
    e => {
      e.returnValue = false
      e.stopPropagation()

      setDistance(DEFAULT_DISTANCE)
      setStartX(e.touches[0].clientX)
      setStartY(e.touches[0].clientY)
      setIsSwiping(true)
    },
    [setStartX, setStartY, setIsSwiping],
  )

  const handleTouchMove = useCallback(
    e => {
      e.returnValue = false
      e.stopPropagation()

      if (!isSwiping) return

      const currentX = e.touches[0].clientX
      const currentY = e.touches[0].clientY

      const distance = getSwipeDistance(currentX, currentY, startX, startY)

      if (!ignoreScroll) {
        const swipeDirectionLocal = getSwipeDirection({ dx: distance.x, dy: distance.y })

        const isScrolling = hasScrollableParent(e, swipeDirectionLocal)

        if (isScrolling) return resetState()
      }

      if (distance.all < safeDistance) return
      const direction = getSwipeDirection({
        dx: distance.x,
        dy: distance.y,
        direction: swipeDirection,
        withAxisFixation,
      })

      setDistance(distance)
      setSwipeDirection(direction)
    },
    [
      resetState,
      isSwiping,
      startX,
      startY,
      setDistance,
      setSwipeDirection,
      safeDistance,
      swipeDirection,
      withAxisFixation,
      ignoreScroll,
    ],
  )

  const handleTouchEnd = useCallback(
    e => {
      e.returnValue = false
      e.stopPropagation()

      if (!isSwiping) return
      if (onSwipeEnd) {
        const currentX = e.changedTouches[0].clientX
        const currentY = e.changedTouches[0].clientY

        const distance = getSwipeDistance(currentX, currentY, startX, startY)
        const direction = getSwipeDirection({
          dx: distance.x,
          dy: distance.y,
          direction: swipeDirection,
          withAxisFixation,
        })

        const distanceForDirection =
          direction === 'left' || direction === 'right' ? distance.x : distance.y

        if (Math.abs(distanceForDirection) > safeDistance)
          onSwipeEnd(direction, distanceForDirection)
      }
      resetState()
    },
    [
      resetState,
      isSwiping,
      onSwipeEnd,
      startX,
      startY,
      swipeDirection,
      withAxisFixation,
      safeDistance,
    ],
  )

  return {
    swipeDirection,
    distance,
    isSwiping,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
  }
}
