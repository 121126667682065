import React from 'react'
import classNames from 'classnames'
import cls from './bordered-block.module.scss'

/**
 * Renders a bordered block component.
 *
 * @param {JSX.Element} children - The content to be displayed inside the bordered block.
 * @param {boolean} isRow - Flag indicating if the block should be displayed as a row.
 * @param {React.CSSProperties} style - Inline styles for the bordered block.
 * @return {JSX.Element} The bordered block component.
 */
export const BorderedBlock = ({ children, isRow, style }) => {
  return (
    <div style={style} className={classNames(cls.block, { [cls.row]: isRow })}>
      {children}
    </div>
  )
}
