import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Text from '~src/new/shared/ui/Text/Text'
import CellSelect from './cells/CellSelect'
import CellInfo from './cells/CellInfo'
import cls from './table-view.module.scss'
import { Link } from 'react-router-dom'

/**
 *
 * @param {import('./TableView.types').TableViewCellExtendType} cell
 * @param {number} rowIndex
 * @param {boolean} [isHeader]
 * @return {JSX.Element}
 */
const cellParser = (cell, rowIndex, isHeader) => {
  if (!cell) return null
  if (typeof cell === 'string') {
    return (
      <Text variant={isHeader ? 'BUTTON' : 'MAIN'} style={{ wordBreak: 'break-word' }}>
        {cell}
      </Text>
    )
  }

  if (typeof cell === 'object' && 'type' in cell)
    switch (cell.type) {
      case 'select':
        return <CellSelect cell={cell} />
      case 'info':
        return <CellInfo cell={cell} />
    }

  return <>{cell}</>
}

/**
 * @param {Object} params
 * @param {import('./TableView.types').TableViewRowType} params.row
 * @param {number} params.rowIndex
 * @param {number[]} [params.flexColumnSizes]
 * @param {string[]} [params.flexRowAlign]
 * @param {string} [params.alignItemsCell]
 * @param {boolean} [params.withHeader]
 * @param {string} [params.classNameRow]
 * @return {React.ReactNode}
 */
const rowParser = ({
  row,
  rowIndex,
  flexColumnSizes,
  flexRowAlign,
  alignItemsCell,
  withHeader,
  classNameRow,
  gap,
}) => {
  if (!row) return null
  let isLink = false
  let forceIsHeader = false
  let Container = 'div'
  const additionalProps = {}
  // @ts-ignore
  const additionalRowStyles = {
    ...(row?.rowStyles || {}),
  }
  if (gap) additionalRowStyles.gap = gap
  // @ts-ignore
  const className = row?.className || ''
  if (!Array.isArray(row) && 'type' in row)
    switch (row.type) {
      case 'divider':
        if (row.likeHeader && Array.isArray(row.label)) {
          row = row.label
          forceIsHeader = true
          additionalRowStyles.marginTop = '20px'
          additionalRowStyles.marginBottom = '10px'
          break
        } else
          return (
            <div
              key={rowIndex}
              className={classNames(cls.divider, row.likeHeader && cls.header, className)}
              style={{
                marginTop: row.likeHeader ? '20px' : undefined,
                ...additionalRowStyles,
              }}
            >
              <Text
                variant="BUTTON"
                style={{
                  textAlign: 'left',
                }}
              >
                {row.label}
              </Text>
            </div>
          )
      case 'link':
        Container = Link
        additionalProps.to = row.href
        row = row.row
        isLink = true
        break
      case 'static':
        row = row.row
        break
      default:
        break
    }

  if (!row) return null
  if (!Array.isArray(row)) return null

  let cellIndexCounter = 0

  const isHeader = (rowIndex === 0 && withHeader) || forceIsHeader

  return (
    // @ts-ignore
    <Container
      key={rowIndex}
      className={classNames(
        isHeader ? cls.header : cls.row,
        isLink && cls.rowLink,
        className,
        classNameRow,
      )}
      style={additionalRowStyles}
      {...additionalProps}
    >
      {row?.map((cell, cellIndex) => {
        if (cell === undefined || cell === null) return null

        const getFlex = size => {
          if (size === 'auto') return 'none'
          if (typeof size === 'number') return size
          if (typeof size === 'string') {
            if (size.includes('px') && !size.includes(' ')) return `0 0 ${size}`
            return size
          }
        }

        const style = {
          flex: getFlex(flexColumnSizes[cellIndexCounter]) ?? 1,
          justifyContent: flexRowAlign[cellIndexCounter] || 'flex-start',
        }

        if (alignItemsCell) style.alignItems = alignItemsCell

        if (cell?.isLikeRow) {
          style.flex = 'none'
          style.width = '100%'
          cell = cell.cell
        } else {
          cellIndexCounter++
        }

        return (
          <div key={cellIndex} className={cls.cell} style={style}>
            {cellParser(cell, rowIndex, isHeader)}
          </div>
        )
      })}
    </Container>
  )
}

/**
 *
 * @param {import('./TableView.types').TableViewPropsType} props
 * @return {JSX.Element}
 */
const TableView = props => {
  const {
    style,
    className,
    classNameTable,
    classNameRow,
    title,
    ref,
    rows,
    buttons = [],
    flexColumnSizes = [],
    flexRowAlign = [],
    alignItemsCell,
    activeFilterList,
    emptyLabel,
    withHeader = true,
    gap,
  } = props

  const rowsElements = useMemo(
    () =>
      rows?.map((row, rowIndex) =>
        rowParser({
          row,
          rowIndex,
          flexColumnSizes,
          flexRowAlign,
          alignItemsCell,
          withHeader,
          classNameRow,
          gap,
        }),
      ),
    [rows],
  )

  const isEmpty = !rows || !rows?.length || (withHeader && rows.length === 1)

  return (
    <div ref={ref} className={classNames(className, cls.container)} style={style}>
      <div className={cls.titleBlock}>
        <Text variant="HEADER">{title}</Text>
        <div className={cls.buttons}>{buttons}</div>
      </div>
      {activeFilterList ? <div className={cls.activeFilterList}>{activeFilterList}</div> : null}

      <div className={classNameTable}>
        {rowsElements}
        {isEmpty && <Text className={cls.emptyLabel}>{emptyLabel}</Text>}
      </div>
    </div>
  )
}

TableView.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  ref: PropTypes.object,
  cls: PropTypes.object,
  buttons: PropTypes.arrayOf(PropTypes.node),
  rows: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]))
      .isRequired,
  ).isRequired,
  flexColumnSizes: PropTypes.arrayOf(PropTypes.number),
  flexRowAlign: PropTypes.arrayOf(PropTypes.oneOf(['center', 'flex-start', 'flex-end'])),
  alignItemsCell: PropTypes.oneOf(['center', 'flex-start', 'flex-end']),
  activeFilterList: PropTypes.array,
  emptyLabel: PropTypes.string,
}

export default TableView
