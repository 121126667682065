import React from 'react'
import cls from './switch.module.scss'
import classNames from 'classnames'

/**
 * A custom switch component.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.checked - The current state of the switch.
 * @param {import('react').ChangeEventHandler<HTMLInputElement>} [props.onChange] - The callback function to handle switch state changes.
 * @param {string} [props.checkedColor] - The color to apply when the switch is checked.
 * @param {string} [props.className] - The CSS class name for the switch.
 * @param {React.CSSProperties} [props.style] - The inline styles for the switch.
 * @returns {JSX.Element} The switch component.
 */
export const Switch = ({ checked, onChange, checkedColor, className, style }) => {
  const styleSpan = {
    backgroundColor: checkedColor,
  }

  return (
    <label className={classNames(cls.switch, className)} style={style}>
      <input type="checkbox" checked={checked} onChange={onChange} />
      <span className={cls.slider} style={checkedColor && checked ? styleSpan : null}></span>
    </label>
  )
}
