import { useCallback, useEffect, useState } from 'react'

const isValue = value => value !== null && value !== undefined

export const useLocalStorageValue = (key, initialValue = '') => {
  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem(key)
    return isValue(storedValue) ? storedValue : initialValue
  })

  useEffect(() => {
    if (key && isValue(value)) localStorage.setItem(key, value)
  }, [key, value])

  const clear = useCallback(() => {
    localStorage.removeItem(key)
    setValue(initialValue)
  }, [setValue, key, initialValue])

  const setValueHandler = useCallback(e => setValue(e.target.value), [setValue])

  return { value, setValue, setValueHandler, clear }
}
