import React, { useEffect, useRef, useState } from 'react'
import * as PropTypes from 'prop-types'

export const SafeAbsolutePopup = props => {
  const { children, className, style = {}, disabled, onClick } = props

  const [safeStyle, setSafeStyle] = useState({})

  const ref = useRef(null)

  useEffect(() => {
    if (!ref.current) return
    if (disabled) {
      if (Object.keys(safeStyle).length) setSafeStyle({})
      return
    }
    const height = ref.current.clientHeight

    const top = ref.current.getBoundingClientRect().top + window.scrollY
    const bottom = document.body.scrollHeight - top

    if (bottom < height) {
      const style = {
        bottom: -bottom,
        top: 'auto',
      }
      setSafeStyle(style)
    } else if (top < 0) {
      const style = {
        top: 0,
        bottom: 'auto',
      }
      setSafeStyle(style)
    }
  }, [ref.current, disabled])

  return (
    <div className={className} style={{ ...style, ...safeStyle }} onClick={onClick} ref={ref}>
      {children}
    </div>
  )
}

SafeAbsolutePopup.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
}
