import React from 'react'
import classNames from 'classnames'
import { SvgIcon } from '~src/new/shared/ui/SvgIcon/SvgIcon'
import { Button } from '~src/new/shared/ui/Button/Button'
import { ReactComponent as CrossIcon } from '~shared/assets/img/icons/cross-icon.svg'
import cls from './close-button.module.scss'

/**
 * @typedef {Object} CloseButtonProps
 * @property {undefined | 'OUTLINE'} [variant]
 */

/**
 *
 * @param {ClassAttributes<HTMLButtonElement> & React.ButtonHTMLAttributes<HTMLButtonElement> & CloseButtonProps} props
 * @return {JSX.Element}
 * @constructor
 */
export const CloseButton = props => {
  const { className, variant, ...otherProps } = props
  return (
    <Button
      variant="ICON"
      className={classNames(cls.closeButton, cls[variant], className)}
      {...otherProps}
    >
      <SvgIcon name={'close'} icon={CrossIcon} />
    </Button>
  )
}
