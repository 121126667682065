import React from 'react'
import classNames from 'classnames'
import { withRouter } from 'react-router-dom'

import Icon from '@material-ui/core/Icon/Icon'
import cls from './back-button-line.module.scss'
import { Button } from '~src/new/shared/ui/Button/Button'
import { ArrowRoundButton } from '~src/new/shared/ui/ArrowRoundButton/ArrowRoundButton'
import { useWidth } from '~src/new/app/providers/WidthProvider'
import { goBack } from '~src/new/shared/lib/history'
import { useHistoryStore } from '~src/new/app/providers/HistoryProvider'

const ICON_STYLE = {
  position: 'relative',
  top: '-2px',
}

const BackButtonLineElement = props => {
  const {
    className,
    style,
    id,
    disabled,
    onClick,
    label = 'Назад',
    history,
    isHistoryBackFirst,
  } = props

  const { isMobile } = useWidth()

  const { historyStore, goBackInHistoryStore } = useHistoryStore()

  // TODO: is copy of PopupBarModule
  const close = () => {
    goBack({ history, onClick, isHistoryBackFirst, historyStore })
    goBackInHistoryStore()
  }

  return (
    <div className={classNames(cls.container, className)} style={style}>
      <Button
        variant="PLAIN"
        id={id}
        disabled={disabled}
        aria-label="Back"
        // className={classNames(className, cls.arrow, disabled && cls.disabled)}
        onClick={close}
      >
        {isMobile ? (
          <Icon
            style={{
              fontSize: '24px',
              ...ICON_STYLE,
            }}
          >
            keyboard_arrow_left
          </Icon>
        ) : (
          <ArrowRoundButton style={ICON_STYLE} isDiv direction="left" variation="OUTLINE" />
        )}
        <span>{label}</span>
      </Button>
    </div>
  )
}

export const BackButtonLine = withRouter(BackButtonLineElement)
