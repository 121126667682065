import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import cls from './swiper-core.module.scss'

const TIMEOUT = 1000 / 24

/**
 * @param {React.RefObject} ref
 * @param {boolean?} [isScrollWidth]
 * @return {number}
 */
const useElementWidthByRef = (ref, isScrollWidth) => {
  const [containerWidth, setContainerWidth] = useState(0)

  const getContainerWidth = useCallback(() => {
    if (!ref.current) return

    setContainerWidth(isScrollWidth ? ref.current.scrollWidth : ref.current.clientWidth)
  }, [ref.current])

  useEffect(() => {
    if (!ref.current) return
    const interval = setInterval(getContainerWidth, TIMEOUT)
    // @ts-ignore
    return () => clearInterval(interval)
  }, [ref.current])

  return containerWidth
}

const PERCENT_TO_NEXT = 65
const TIME_TO_NEXT = 500
const TIME_TO_STOP = 5000

const animateScroll = (element, to, duration) => {
  const SAFE_TIME = 1000
  return new Promise(resolve => {
    const safeTimer = setTimeout(() => {
      resolve()
    }, SAFE_TIME + duration)
    const start = element.scrollLeft
    const change = to - start
    const step = change / duration

    let startTime = null

    const animate = elapsedTime => {
      if (!startTime) startTime = elapsedTime

      const elapsed = elapsedTime - startTime

      const mathLock = step > 0 ? Math.min : Math.max

      element.scrollLeft = mathLock(start + step * elapsed, to)
      if (elapsed < duration) {
        requestAnimationFrame(animate)
      } else {
        clearTimeout(safeTimer)
        resolve()
      }
    }
    requestAnimationFrame(animate)
  })
}

// TODO: if use [elementsToShow] in SwiperCore, then it show with lags
/**
 * SwiperCore component for handling swipe functionality.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.elements - The array of elements to be displayed in the swiper.
 * @param {number} props.activeIndex - The index of the currently active element.
 * @param {function} props.setActiveIndex - The function to set the active index.
 * @param {function} [props.setMaxIndex] - The function to set the max index.
 * @param {number} [props.elementsToShow] - The number of elements to show at once.
 * @param {string} [props.className] - The CSS class name for the swiper container.
 * @param {string} [props.classNameElements] - The CSS class name for the swiper elements container.
 * @param {Object} [props.style] - The inline styles for the swiper container.
 * @param {Object} [props.styleScrollContainer] - The inline styles for the swiper elements container.
 * @returns {JSX.Element} The rendered SwiperCore component.
 */
export const SwiperCore = props => {
  const {
    elements,
    activeIndex,
    setActiveIndex,
    setMaxIndex: setMaxIndexGlobal,
    elementsToShow,
    className,
    classNameElements,
    styleScrollContainer,
    isCenterMode,
  } = props

  let { style } = props

  const [maxIndex, setMaxIndex] = useState(0)
  const [isDragging, setIsDragging] = useState(false)
  const [isMoving, setIsMoving] = useState(false)
  const [childWidth, setChildWidth] = useState(0)

  const timer = useRef(null)
  const timerDrag = useRef(null)
  const isLock = useRef(false)

  const containerRef = useRef(null)
  const elementsRef = useRef(null)

  const elementsWidth = useElementWidthByRef(elementsRef, true)
  const containerWidthByRef = useElementWidthByRef(containerRef)
  const containerWidthByElements = useMemo(() => {
    if (!childWidth || (!elements?.length && !elementsToShow)) return 0
    const length = elementsToShow || elements.length
    return childWidth * length
  }, [childWidth, elements, elementsToShow])

  const containerWidth = useMemo(
    () => (elementsToShow ? containerWidthByElements : containerWidthByRef),
    [elementsToShow, containerWidthByElements, containerWidthByRef],
  )

  const isScrollable = useMemo(() => {
    if (!containerWidth || !elementsWidth) return false
    return elementsWidth > containerWidth
  }, [containerWidth, elementsWidth])

  useEffect(() => {
    let activeIndexLocal = activeIndex
    if (activeIndexLocal < 0) activeIndexLocal = 0
    if (activeIndexLocal > maxIndex) activeIndexLocal = maxIndex
    moveScrollToElement(activeIndexLocal)

    if (activeIndexLocal !== activeIndex) {
      setActiveIndex(activeIndexLocal)
    }
  }, [activeIndex])

  useEffect(() => {
    const maxScrollX = elementsWidth - containerWidth
    const childWidth = elementsWidth / elements.length
    let maxIndex = childWidth ? Math.ceil(maxScrollX / childWidth) : 0
    // контейнер не скроллится
    if (maxScrollX <= 0) maxIndex = -1

    setMaxIndex(maxIndex)
    setMaxIndexGlobal && setMaxIndexGlobal(maxIndex)
    setChildWidth(childWidth)
  }, [containerWidth, elementsWidth])

  useEffect(() => {
    if (isDragging) return
    if (!elementsRef.current) return
    const scrollX = elementsRef.current.scrollLeft
    const activeIndexDiff = (scrollX % childWidth) / childWidth
    let activeIndex = Math.floor(scrollX / childWidth)

    if (activeIndexDiff * 100 > PERCENT_TO_NEXT) {
      activeIndex++
    }

    if (scrollX + elementsRef.current.clientWidth === elementsRef.current.scrollWidth)
      activeIndex = maxIndex

    setActiveIndex(activeIndex || 0)
    moveScrollToElement(activeIndex || 0)
  }, [isDragging])

  const moveScrollToElement = useCallback(
    index => {
      if (!elementsRef.current) return

      if (
        index === maxIndex &&
        elementsRef.current.scrollLeft + elementsRef.current.clientWidth ===
          elementsRef.current.scrollWidth
      )
        return
      isLock.current = true
      animateScroll(elementsRef.current, index * childWidth, 300).then(() => {
        isLock.current = false
      })
    },
    [childWidth, maxIndex],
  )

  const endScroll = useCallback(() => {
    if (timer.current) clearTimeout(timer.current)
    setIsDragging(false)
    setIsMoving(false)
  }, [])

  const onScroll = useCallback(() => {
    if (isLock.current) return

    if (timer.current) clearTimeout(timer.current)
    setIsDragging(true)
    timer.current = setTimeout(endScroll, TIME_TO_NEXT)
  }, [childWidth])

  const startDrag = useCallback(e => {
    e.preventDefault()
    setIsDragging(true)
    if (timer.current) clearTimeout(timer.current)

    const startX = e.clientX
    const startScrollX = elementsRef.current.scrollLeft
    const dateDragStart = Date.now()

    const onMouseMove = e => {
      const deltaX = startX - e.clientX
      if (Math.abs(deltaX) < 10) return
      setIsMoving(true)

      const scrollX = startScrollX + deltaX
      requestAnimationFrame(() => {
        if (!elementsRef.current) return
        elementsRef.current.scrollLeft = scrollX
      })
    }

    const onMouseUp = e => {
      const diffTime = Date.now() - dateDragStart
      if (diffTime > 100) {
        e.preventDefault()
        e.stopPropagation()
      }
      if (!containerRef.current) return
      document.removeEventListener('mousemove', onMouseMove)
      endScroll()
      document.removeEventListener('mouseup', onMouseUp)
    }

    document.addEventListener('mousemove', onMouseMove)
    document.addEventListener('mouseup', onMouseUp)

    if (timerDrag.current) clearTimeout(timerDrag.current)
    timerDrag.current = setTimeout(() => {
      if (!containerRef.current) return
      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('mouseup', onMouseUp)
    }, TIME_TO_STOP)
  }, [])

  if (!elements || elements.length === 0) return null

  const styleContainer = {}

  if (style) Object.assign(styleContainer, style)

  if (elementsToShow) {
    const widthToShow = childWidth * elementsToShow

    if (isScrollable || widthToShow < containerWidth)
      if (isCenterMode) {
        styleContainer.width = `100%`
      } else styleContainer.width = `${elementsToShow * childWidth}px`
  }

  const readyToShow = containerWidth && elementsWidth

  return (
    <div
      className={classNames(className, cls.container, {
        [cls.show]: readyToShow,
      })}
      onScroll={onScroll}
      onMouseDown={startDrag}
      onMouseUp={e => e.stopPropagation()}
      ref={containerRef}
      style={styleContainer}
    >
      <div
        className={classNames(
          classNameElements,
          cls.elements,
          isScrollable && isDragging && isMoving && cls.disableEvent,
        )}
        style={styleScrollContainer}
        ref={elementsRef}
      >
        {elements}
      </div>
    </div>
  )
}
