import React from 'react'
import classNames from 'classnames'
import HTMLParser from 'html-parse-stringify'
import Text from '~src/new/shared/ui/Text/Text'
import TrackedVideo from '~components/atoms/TrackedVideo'
import { Button } from '~src/new/shared/ui/Button/Button'
import Grid from '@material-ui/core/Grid'
import cls from './rich-text.module.scss'

const classes = {}
const size = 'size'

const WITH_COLOR_FROM_STYLE = false
let api
let history

const parseAttr = attr => {
  if (!attr) {
    return undefined
  }

  let { class: className, style, useStyle, ...rest } = attr

  if (useStyle) {
    style = style.split(';').reduce((acc, styleLine) => {
      const [key, value] = styleLine.trim().split(':')
      if (key && value) acc[key] = value
      return acc
    }, {})
  } else {
    if (WITH_COLOR_FROM_STYLE) {
      // Hack to remove the color from style and check if we can replace the style to class in quill.js
      let result = /^color:\s?rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\);?$/.exec(style)
      // const hex = /^#([0-9a-f]{3}|[0-9a-f]{6})$/.exec(style)

      if (result) {
        style = { color: `rgb(${result[1]}, ${result[2]}, ${result[3]})` }
      } else {
        style = undefined
      }
    } else {
      style = undefined
    }
  }

  return { className, style, ...rest }
}

const renderTypography = (children, attrs, variant, index) => {
  const attributes = parseAttr(attrs)
  const { className, ...restAttributes } = attributes
  return (
    <Text
      key={index}
      {...restAttributes}
      variant={variant}
      className={classNames(classes.gridItem, className, classes[size])}
    >
      {parse(children)}
    </Text>
  )
}

const onClickHandler = async (e, sitemapid, target, href) => {
  e.preventDefault()
  if (sitemapid) {
    const { request } = api.linkReference({
      _id: sitemapid,
    })

    let response = await request
    let { slug } = response

    if (target === 'popup') {
      let currentPath = history.location.pathname
      if (currentPath.charAt(currentPath.length - 1) === '/') currentPath = currentPath.slice(0, -1)

      if (slug.charAt(0) === '/' && slug.charAt(1) !== '#') slug = `#${slug.slice(1)}?page=true`

      history.push(currentPath + slug)
      return
    } else if (/^\/#/.test(slug)) {
      slug = slug.slice(1)
    }

    history.push(slug)
    return
  }

  window.open(href)
}

const renderButton = (children, attrs, variant, index) => {
  const attributes = parseAttr(attrs)
  const { href, sitemapid, target = '_self' } = attributes
  return (
    <Button
      onClick={e => onClickHandler(e, sitemapid, target, href)}
      to={href}
      key={index}
      variant={'contained'}
      color={'secondary'}
    >
      {parse(children)}
    </Button>
  )
}

const renderAnchor = (children, attrs, Tag) => {
  const attributes = parseAttr(attrs)
  const { className, href, sitemapid, target = '_self', ...restAttributes } = attributes
  return (
    <Tag
      {...restAttributes}
      href={'#'}
      onClick={e => onClickHandler(e, sitemapid, target, href, restAttributes)}
    >
      {parse(children)}
    </Tag>
  )
}

const renderTag = (children, attrs, Tag, props) => {
  return (
    <Tag {...parseAttr(attrs)} {...props}>
      {parse(children)}
    </Tag>
  )
}

const parse = children => {
  return children.map((contentItem, index) => {
    if (!contentItem) {
      return null
    }

    const { type, name, content, children: grandChildren, attrs, voidElement } = contentItem

    let Component

    switch (type) {
      case 'text':
        return (
          <span
            style={{
              wordBreak: 'break-word',
              whiteSpace: 'pre-wrap',
            }}
            key={index}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )
      case 'tag': {
        switch (name) {
          case 'a':
            return renderAnchor(
              grandChildren,
              attrs,
              name,
              {
                key: index,
                className: classNames(classes.gridItem, classes[name], classes[size]),
              },
              { api, history },
            )
          case 'h1':
            return renderTypography(grandChildren, attrs, 'SUBHEADER', index)
          case 'h2':
            return renderTypography(grandChildren, attrs, 'SUBHEADER', index)
          case 'h3':
            return renderTypography(grandChildren, attrs, 'SUBHEADER', index)
          case 'h4':
            return renderTypography(grandChildren, attrs, 'SUBHEADER', index)
          case 'p':
            // if (grandChildren && grandChildren.length === 1 && grandChildren[0].name === 'br') { return <br/> }
            return renderTypography(grandChildren, attrs, 'MAIN', index)
          case 'ol':
          case 'ul':
          case 'pre':
          case 'blockquote':
            return renderTag(grandChildren, attrs, name, {
              key: index,
              className: classNames(classes.gridItem, cls[name], cls[size]),
            })
          case 'video':
            const { src, alt } = attrs

            return (
              <div key={index}>
                <TrackedVideo controls className={classes.image} src={src} name={alt}>
                  {parse(grandChildren, { api, history })}
                </TrackedVideo>
              </div>
            )
          case 'button':
            return renderButton(grandChildren, attrs, name, {
              key: index,
              className: classNames(classes.gridItem, classes[name]),
            })
          case 'img':
            const groupedImages = []
            let sibling = contentItem
            let siblingIndex = index

            while (true) {
              groupedImages.push(sibling)

              if (groupedImages.length === 2) {
                break
              }

              siblingIndex++
              sibling = children.length > siblingIndex && children[siblingIndex]

              const { name: siblingName } = sibling

              if (siblingName !== 'img') {
                break
              }

              children[siblingIndex] = null
            }

            if (groupedImages.length === 1) {
              const { src } = attrs

              return (
                <div key={index}>
                  <img className={classes.image} src={src} alt="" />
                </div>
              )
            }

            return (
              <Grid key={index} container xs={12}>
                {groupedImages.map(image => {
                  const { attrs: { src } = {} } = image
                  return (
                    <Grid item xs={6} className={classes.gridItem}>
                      <div
                        className={classes.image4to3}
                        style={{ backgroundImage: `url(${src})` }}
                      />
                    </Grid>
                  )
                })}
              </Grid>
            )

          case 'br':
            return <br key={index} />

          default:
            Component = name
        }
        break
      }
      default: {
        break
      }
    }

    if (voidElement) {
      return <Component key={index} {...parseAttr(attrs)} />
    }

    return (
      <Component className={cls.container} key={index} {...parseAttr(attrs)}>
        {parse(grandChildren, { api, history })}
      </Component>
    )
  })
}

export const getHeaderFromContent = content => {
  if (!content) return null

  const regex = />(.*?)<\//
  const found = content.match(regex)
  let header = found && found[1]

  if (!header) return null

  const contentWithoutHeader = content.replace(header, '')
  header = header.replace(/<[^>]+>/g, '')

  return { header, contentWithoutHeader }
}

export const renderContent = (
  content,
  { api: apiEx, history: historyEx, wrapClassName, withoutHeader, removeHeader, defaultColor },
) => {
  if (!content) {
    return null
  }
  api = apiEx
  history = historyEx

  if (removeHeader) {
    const { contentWithoutHeader } = getHeaderFromContent(content)
    content = contentWithoutHeader
  }

  // quill doesn't support soft returns, therefore we treat singular returns as a break insinde a paragraph
  // and a double returns as a new paragraph
  const hash = '%%%%%%%%'
  content = content.replace(/'/g, '&apos;')
  content = content.replace(/<p><br><\/p>(?!\s*?<p><br><\/p>)/gi, hash)
  content = content.replace(/<\/div><\/p><p>/gi, '</div>')
  content = content.replace(/<\/p><p>/gi, '<br>')
  content = content.replace(new RegExp(hash, 'g'), '')
  content = content.replace('<p><br></p>', '<br>')
  // Preserve the whitespaces between tags
  const SPACE_ENCLOSED = /(>)(\s+)(<\/?[a-z]+)/gi
  content = content.replace(SPACE_ENCLOSED, (matches, begin, spaces, end) => {
    return `${begin}<span>${spaces}</span>${end}`
  })

  let wrapClassNameParsed = withoutHeader ? '' : cls.withHeader

  if (wrapClassName) {
    wrapClassNameParsed = `${wrapClassNameParsed} ${wrapClassName}`
  }

  const parsedContent = HTMLParser.parse(`<div class=${wrapClassNameParsed}>${content}</div>`)

  const color = defaultColor || 'black'

  return (
    <div className={cls.root} style={{ color }}>
      {parse(parsedContent)}
    </div>
  )
}
