import React from 'react'
import classNames from 'classnames'
import cls from './page-space.module.scss'

/**
 * PageSpace is a functional component that renders a container with optional spacing.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {ReactNode} props.children - The child elements to render within the container.
 * @param {boolean} [props.off] - A flag indicating whether to disable the default spacing.
 * @param {boolean} [props.offMobile] - A flag indicating whether to disable the default spacing on mobile devices.
 */
export const PageSpace = props => {
  const { children, off, offMobile } = props
  if (!children) return null
  return (
    <div
      className={classNames({
        [cls.pageSpace]: !off,
        [cls.excludeMobileSpace]: offMobile,
      })}
    >
      {children}
    </div>
  )
}
