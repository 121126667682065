import React from 'react'
import cls from './spoiler-text-block.module.scss'
import Text from '~src/new/shared/ui/Text/Text'

/**
 * Returns a spoiler text block component with the given label and value.
 *
 * @param {string} label - The label to display.
 * @param {string} value - The value to display.
 * @return {JSX.Element} - The spoiler text block component.
 */
const SpoilerTextBlock = ({ label, value }) => (
  <div className={cls.spoilerTextBlock}>
    <Text variant="MAIN">{label}</Text>
    <Text variant="MAIN">{value}</Text>
  </div>
)

export default SpoilerTextBlock
