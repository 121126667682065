import { createPortal } from 'react-dom'
import * as PropTypes from 'prop-types'

export const ReactPortal = props => {
  const { children, element } = props

  return createPortal(children, element || document.body)
}

ReactPortal.defaultProps = {
  element: document.body,
}

ReactPortal.propTypes = {
  children: PropTypes.node.isRequired,
  element: PropTypes.instanceOf(HTMLElement),
}
