import React from 'react'
import classnames from 'classnames'
import cls from './checkbox-field.module.scss'

export const Checkbox = props => {
  const { checked, onChange, className } = props

  return (
    <>
      <input type="checkbox" checked={checked} onChange={onChange} className={cls.checkbox} />
      <div className={classnames(cls.checkboxFake, className)}>
        <span className={cls.checkmark}>
          <svg
            width="9"
            height="8"
            viewBox="0 0 9 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 2.875L3.69231 6L8 1"
              stroke="black"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
          </svg>
        </span>
      </div>
    </>
  )
}
